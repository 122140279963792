// Chakra imports
import {
    Button,
    Flex,
    Icon, Image,
    Spacer,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React from "react";
import {SaveSVGIcon, Add, TextIconSpacing} from "react-md";
import whiteBook from "../../../../assets/img/logo_cropped.png";
import { FiPlus } from "react-icons/fi";


const NoEntries = ({title, description, button}) => {
    const textColor = useColorModeValue("gray.700", "white");

    return (
        <Card height='600px' p='1.2rem' borderRadius={{sm: "0px", lg: "15px"}}>
            <CardBody w='100%'>
                <Flex flexDirection={{sm: "column", lg: "column"}} w='100%'>
                    <Flex
                        bg='white'
                        opacity="40%"
                        align='center'
                        justify='center'
                        flexDirection="column"
                        marginBottom={{sm: "40px", lg: "0px"}}
                        height={"100%"}
                        minHeight={{sm: "250px"}}>
                        <Image
                            src={whiteBook}
                            alt='chakra image'
                            width="300px"
                        />
                    </Flex>

                    <Flex
                        flexDirection='column'
                        align="center"
                        justify="center"
                        h='100%' opacity={"60%"}>
                        {/*<Text fontSize='sm' color='gray.400' fontWeight='bold'>*/}
                        {/*</Text>*/}
                        <Text fontSize='2xl' color={textColor} fontWeight='bold'>
                            {title}
                        </Text>
                        <Text fontSize='md' color='gray.400' fontWeight='normal'>
                            {description}
                        </Text>
                        {button}
                    </Flex>
                    <Spacer/>
                </Flex>
            </CardBody>
        </Card>
    );
};

export default NoEntries;
