// Chakra imports
import {
    Flex, Icon, SimpleGrid, Text, useColorModeValue, ChakraProvider, Button
} from "@chakra-ui/react";

// Assets
import React from "react";
import theme from 'theme/theme.js';
import NoEntries from "../views/Dashboard/Dashboard/components/NoEntries";
import {TextIconSpacing} from "react-md";
import {FiPlus} from "react-icons/fi";

import {RiHome2Line} from "react-icons/ri";
import {NavLink} from "react-router-dom";

function ComingSoon() {

    return (<ChakraProvider theme={theme} resetCss={false}>
            <Flex
                direction='column'
                justifySelf='center'
                overflow='hidden'
                marginTop="10px">
                <Flex alignItems='center' justifyContent='center' mb='70px' mt='90px'>

                    <Flex
                        direction='column'
                        w={{lg: '70%', sm: '90%'}}
                        background='white' Lin
                        borderRadius='15px'
                        p='40px'
                        boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
                        <NoEntries
                            title={"We are working on it!"}
                            description={"We are working very hard to bring this feature to you as soon as possible"}
                            button={
                                <NavLink to="/home">
                                    <Button id="combined-button-6" marginTop={"20px"} themeType="outline">
                                        <TextIconSpacing icon={<RiHome2Line width={"30px"}/>}>Go Back
                                            Home</TextIconSpacing>
                                    </Button>
                                </NavLink>}
                        />
                    </Flex>
                </Flex>
            </Flex>

        </ChakraProvider>
    );
}

export default ComingSoon;
