// Chakra imports
import {
    Button,
    Flex,
    Icon,
    Spacer,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React from "react";
// react logos
import {BsArrowRight} from "react-icons/bs";

const BuiltByDevelopers = ({title, name, description, image}) => {
    const textColor = useColorModeValue("gray.700", "white");

    return (
        <Card minHeight='290.5px' p='1.2rem' borderRadius={{sm: "0px", lg: "15px"}}>
            <CardBody w='100%'>
                <Flex flexDirection={{sm: "column", lg: "row"}} w='100%'>
                    <Flex
                        flexDirection='column'
                        h='100%'
                        lineHeight='1.6'
                        width={{lg: "45%"}}>
                        <Text fontSize='md' color='gray.400' fontWeight='bold'>
                            {title}
                        </Text>
                        <Text fontSize='2xl' color={textColor} fontWeight='bold' pb='.5rem'>
                            {name}
                        </Text>
                        <Text fontSize='md' color='gray.400' fontWeight='normal'>
                            {description}
                        </Text>
                        <Spacer/>
                        <Flex align='center'>

                        </Flex>
                    </Flex>
                    <Spacer/>
                    <Flex
                        bg='white'
                        opacity="60%"
                        align='center'
                        justify='center'
                        flexDirection="column"
                        borderRadius='10px'
                        width={{lg: "40%"}}
                        minHeight={{sm: "250px"}}>
                        {image}
                    </Flex>

                </Flex>
            </CardBody>
        </Card>
    );
};

export default BuiltByDevelopers;
