import {
    Flex, Grid,
    Icon,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React from "react";
import {BsArrowRight, BsFillArrowRightCircleFill} from "react-icons/bs";
import {NavLink} from "react-router-dom";

const GiveUsYourData = ({title, name, description, image}) => {
    const textColor = useColorModeValue("gray.700", "white");

    return (
        <Card p='1.2rem' borderRadius={{sm: "0px", lg: "15px"}}>
            <CardBody w='100%'>

                <Flex flexDirection={"row"} w='100%'>
                    <Grid
                        templateColumns={{sm: "1fr", md: "1fr", lg: "1fr"}}>
                        <Flex
                            flexDirection='column'
                            h='100%'
                            lineHeight='1.6'>
                            <Text fontSize={{sm: 'md', lg: 'md'}} color='gray.400' fontWeight='bold'>
                                {title}
                            </Text>
                            <Text fontSize={{sm: 'lg', lg: '2xl'}} color={textColor} fontWeight='bold'>
                                {name}
                            </Text>
                            <Text fontSize={{sm: 'md', lg: 'lg'}} color='gray.400' fontWeight='normal'>
                                {description}
                            </Text>
                        </Flex>


                        <NavLink to="/fill">
                            <Flex _hover={{transform: " translateX(1%)"}}
                                  transition='all .5s ease'
                                  mt={{sm: "15px", lg: "35px"}}
                                  flexDirection={"row"}
                            justifyContent={"center"}>
                                <Text fontSize={{sm: "sm", lg: 'md'}} textColor={"teal"}
                                      fontWeight={"bold"}>
                                    TELL OTHERS ABOUT YOUR DEAL
                                </Text>
                                <BsArrowRight size={20} color={"teal"}></BsArrowRight>
                            </Flex>
                        </NavLink>
                    </Grid>
                </Flex>
            </CardBody>
        </Card>
    );
};

export default GiveUsYourData;
