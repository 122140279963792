// Chakra imports
import {
    Avatar,
    AvatarGroup,
    Box,
    Button,
    Flex,
    Image,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import {BsArrowRight} from "react-icons/bs";
import {VerticalDivider} from "react-md";
import {useToggle} from "@react-md/utils";
import CarFormPreviewDialog from "../../../Form/CarFormPreviewDialog";

const ProjectCardDashboard = ({pk, make, image, name, category, avatars, description}) => {
    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");
    const [visible, enable, disable] = useToggle(false);

    return (
        <Flex direction='column'
              boxShadow='0 0px 5px 0 rgb(0 0 0 / 3%)'
              padding={"10px"}
              minHeight={"180px"}
              justifyContent={"space-around"}
              borderRadius={"5px"}
              onClick={() => {
                  enable()
              }}>
            <Flex direction='column'>
                <Text fontSize={{sm: "sm", lg: 'md'}} color='gray.500' fontWeight='600'>
                    {make}
                </Text>

                <Text fontSize={{sm: "lg", lg: 'xl'}} color={textColor} fontWeight='bold' lineHeight={"6"}>
                    {category}
                </Text>
                <Text fontSize={{sm: "sm", lg: 'md'}} color='gray.500' fontWeight='600' mt='0px'>
                    {name}
                </Text>
                <Text fontSize={{sm: "xl", lg: '2xl'}} color='teal' fontWeight='800' mt='5px' flexWrap={"wrap"}>
                    {description}
                </Text>
                <Flex _hover={{transform: "scale(105%) translateX(5%)"}}
                      transition='all .5s ease'
                      mt="10px"
                      flexDirection="row">
                    <Text fontSize={{sm: "xs", lg: 'md'}}
                          fontWeight={"bold"}>
                        DETAILS
                    </Text>
                    <BsArrowRight color={"teal"} style={{marginTop: "5px", marginLeft: "5px"}}></BsArrowRight>
                </Flex>
            </Flex>
            <CarFormPreviewDialog id={pk}
                                  shouldShow={visible}
                                  onClose={disable}></CarFormPreviewDialog>
        </Flex>
    );
};

export default ProjectCardDashboard;
