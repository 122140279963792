// Chakra imports
import {ChakraProvider, useColorModeValue, useDisclosure} from '@chakra-ui/react';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// Custom Chakra theme
import theme from 'theme/theme.js';
// Custom components
import IntroCard from '../views/Dashboard/Dashboard/components/IntroCard';
import deal from "assets/img/deal.jpg"
import ProfileBgImage from "assets/img/ProfileBackground.png";


import {Grid, Flex, Image, Portal} from '@chakra-ui/react';
import BuiltByDevelopers from 'views/Dashboard/Dashboard/components/BuiltByDevelopers';
import whiteBook from "assets/img/logo.png"
import HeaderCarSelector from 'components/CarSelector'
import GiveUsYourData from 'views/Dashboard/Dashboard/components/GiveUsYourData';

import AuthNavbar from 'components/Navbars/AuthNavbar';
import Entries from "../views/Dashboard/Tables/components/Entries";
import Projects from "../views/Dashboard/Profile/components/Projects";

export default function Dashboard(props) {
    document.documentElement.dir = 'ltr';
    return (
        <ChakraProvider theme={theme} resetCss={false}>
            <Flex
                alignSelf={"center"}
                flexDirection={"row"}
                justifyContent={"center"}
                marginBottom={"0px"}
                marginTop={{sm: "0px", lg: "50px"}}
                marginX={{sm: "0px", lg: "50px", xl: "50px"}}
            >
                <Flex flexDirection='column'
                      width={"100%"}
                      maxWidth={"1600px"}
                      paddingBottom="0px">
                    <AuthNavbar secondary={false} logoText=''/>

                    <Grid
                        templateColumns={{md: "1fr", lg: "2.5fr 1fr"}}
                        templateRows={{md: "1fr auto", lg: "1fr"}}
                        my={{sm: "2px", lg: "26px"}}
                        gap={{sm: "1px", lg: '24px'}}>
                        <IntroCard
                            backgroundImage={deal}
                            title={"It's simple. We tell you what others are paying for their cars."}
                            description={
                                ""
                            }
                        />

                        <GiveUsYourData
                            title={"Join us for a chance to win $100!"}
                            name={"Share your car payments today!"}
                            description={
                                "Join our community to discover what others are truly paying for their cars. " +
                                "Say goodbye to hidden costs and make smarter, money-saving choices on your next car purchase."}
                            image={
                                <Image
                                    src={whiteBook}
                                    alt='chakra image'
                                    width="100px"
                                />
                            }>

                        </GiveUsYourData>
                    </Grid>

                    <HeaderCarSelector
                        backgroundHeader={ProfileBgImage}
                    />
                    <Projects title="Recently Reported Deals"
                              description={"Reported by our users to help you negotiate"}> </Projects>
                </Flex>
            </Flex>
        </ChakraProvider>
    );
}
