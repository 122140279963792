// Chakra imports
import {
    Flex,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import React from "react";
import {createTheme, ThemeProvider} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import api from "../../../../api/api";
import Cashes from "./Cashes";
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Card} from "react-md";
import ProjectCardDashboard from "../../Profile/components/ProjectCardDashboard";
import imageArchitect1 from "../../../../assets/img/projects-1.jpg";
import {Grid} from "@chakra-ui/react"

const FinancesCardV2 = ({make, model}) => {

    const theme = createTheme({
        palette: {
            secondary: {
                main: '#E33E7F',
            }
        }
    });


    const [purchasedState, setPurchasedState] = React.useState("none");
    const [cash, setCash] = React.useState([]);

    const [cashPages, setCashPages] = React.useState(1)
    const [cashPage, setCashPage] = React.useState(1)

    React.useEffect(() => {
        api.send("GET", "/finance/all/" + make + "/" + model + "/" + purchasedState + "/" + (cashPage ), undefined, function (err, res) {
            // No Error expected
            console.log("Cash requested!!")
            if (res != null) {
                console.log("Cash response is not null!!")
                console.log(res)
                setCash(res.list_data)
                setCashPages(res.page_count)
            } else {
                setCashPages({"list_data": [], "page_count": 0})
            }
        });
    }, [purchasedState, cashPage, make, model])


    return (
        cash.length != 0 ?
            <Flex direction='column'
                  boxShadow='0 2px 5px 0 rgb(0 0 0 / 6%)'
                  padding={"10px"}
                  marginTop={{sm: "3px", lg: "20px"}}
                  borderRadius={"15px"}
                  background={"white"}
            >
                <Flex direction='column'>
                    <Text fontSize={{sm: 'xl', lg: 'xl'}} fontWeight='bold' pb='.5rem' ps={"10px"} pt={"10px"}
                          mb={"5px"}>
                        Financed for
                    </Text>
                </Flex>
                <Grid
                    width={"100%"}
                    templateColumns={{sm: "1fr 1fr", md: "repeat(3, 1fr)", lg: "repeat(3, 1fr)", xl: "repeat(3, 1fr)"}}
                    gap='5px'>
                    {cash.map((row) => {
                        return <ProjectCardDashboard
                            h={"100%"}
                            pk={row.pk}
                            make={row.fields.dealership}
                            image={imageArchitect1}
                            category={row.fields.trim}
                            name={"$ " + row.fields.finance.down + " down"}
                            description={"$" + row.fields.finance.monthly +
                                " / month"}
                            avatars={[]}
                        />
                    })}
                </Grid>

                {cashPages > 1 ?
                    <Flex alignContent={"center"}
                          marginTop="10px"
                          marginBottom={"10px"}
                          justifyContent={"center"}>
                        <ThemeProvider theme={theme}>
                            <Pagination count={cashPages}
                                        onChange={(event, page) => {
                                            setCashPage(page)
                                            console.log(page)
                                        }}
                                        color="secondary"/>
                        </ThemeProvider>
                    </Flex> :
                    <p></p>
                }

            </Flex> :
            <p></p>
    );
};

export default FinancesCardV2;
