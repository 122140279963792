// Chakra imports
import {
    Flex, Icon, SimpleGrid, Text, useColorModeValue, ChakraProvider, Button, Image, Spacer
} from "@chakra-ui/react";

// Assets
import React from "react";
import theme from 'theme/theme.js';
import NoEntries from "../views/Dashboard/Dashboard/components/NoEntries";
import {TextIconSpacing} from "react-md";
import {FiPlus} from "react-icons/fi";

import {RiHome2Line} from "react-icons/ri";
import {NavLink} from "react-router-dom";
import Card from "../components/Card/Card";
import CardBody from "../components/Card/CardBody";
import whiteBook from "../assets/img/logo_cropped.png";

function AboutUs() {

    const textColor = useColorModeValue("gray.700", "white");

    return (<ChakraProvider theme={theme} resetCss={false}>
            <Flex
                direction='column'
                justifySelf='center'
                overflow='hidden'
                marginTop="10px">
                <Flex alignItems='center' justifyContent='center' mb='70px' mt='90px'>

                    <Flex
                        direction='column'
                        w={{lg: '60%', sm: '90%'}}
                        background='white' Lin
                        borderRadius='15px'
                        p='40px'
                        boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>

                        <Card height='100%' p='1.2rem' borderRadius={{sm: "0px", lg: "15px"}}>
                            <CardBody w='100%'>
                                <Flex flexDirection={{sm: "column", lg: "column"}} w='100%'>
                                    <Flex
                                        bg='white'
                                        opacity="40%"
                                        align='center'
                                        justify='center'
                                        flexDirection="column"
                                        marginBottom={{sm: "40px", lg: "0px"}}
                                        height={"100%"}
                                        minHeight={{sm: "250px"}}>
                                        <Image
                                            src={whiteBook}
                                            alt='chakra image'
                                            width="300px"
                                        />
                                    </Flex>

                                    <Flex
                                        flexDirection='column'
                                        align="center"
                                        justify="center"
                                        marginStart={"20%"}
                                        h='100%' w="60%" opacity={"60%"}>
                                        <Text fontSize='2xl' textAlign={"center"} color={textColor} fontWeight='bold'>
                                            {"We want to democratize car finances to help everyone save money on their cars"}
                                        </Text>
                                        <Text textAlign={"center"} fontSize='md' color='gray.400' fontWeight='normal'>
                                            {"Join our community to discover what others are truly paying for their cars. " +
                                                "Say goodbye to hidden costs and make smarter, money-saving choices on your next car purchase."}
                                        </Text>
                                        <NavLink to="/home">
                                            <Button id="combined-button-6" marginTop={"50px"} themeType="outline">
                                                <TextIconSpacing icon={<RiHome2Line width={"30px"}/>}>Go Back
                                                    Home</TextIconSpacing>
                                            </Button>
                                        </NavLink>
                                    </Flex>
                                    <Spacer/>
                                </Flex>
                            </CardBody>
                        </Card>

                    </Flex>
                </Flex>
            </Flex>

        </ChakraProvider>
    );
}

export default AboutUs;
