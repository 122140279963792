// Chakra imports
import {
    Flex, Icon, Image, Progress, Spacer, Text, useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React from "react";
import whiteBook from "../../../../assets/img/logo_cropped.png";
import {mirage} from "ldrs";

const LoadingEntries = ({}) => {
    const textColor = useColorModeValue("gray.700", "white");
    mirage.register()

    return (<Card height='600px' p='1.2rem' borderRadius={{sm: "0px", lg: "15px"}}>
        <CardBody w='100%'>
            <Flex flexDirection={{sm: "column", lg: "column"}} w='100%'>
                {/*<Flex*/}
                {/*    bg='white'*/}
                {/*    opacity="40%"*/}
                {/*    align='center'*/}
                {/*    justify='center'*/}
                {/*    flexDirection="column"*/}
                {/*    marginBottom={{sm: "40px", lg: "0px"}}*/}
                {/*    height={"100%"}*/}
                {/*    minHeight={{sm: "250px"}}>*/}
                {/*    <Image*/}
                {/*        src={whiteBook}*/}
                {/*        alt='chakra image'*/}
                {/*        width="300px"*/}
                {/*    />*/}
                {/*</Flex>*/}

                <Flex
                    flexDirection='column'
                    align="center"
                    justify="center"
                    h='100%' opacity={"60%"}>
                </Flex>
            </Flex>
        </CardBody>
        <l-mirage
            style={{width:"100%", height:"100%"}}
            size="100"
            speed="5"
            color="teal"
        />
    </Card>);
};

export default LoadingEntries;
