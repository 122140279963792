// Chakra imports
import {
    Flex, Icon, SimpleGrid, Text, useColorModeValue, ChakraProvider, Grid, Image
} from "@chakra-ui/react";

import {
    BsFillArrowRightCircleFill, BsCurrencyDollar, BsPercent, BsQuestionCircle
} from "react-icons/bs";
import {useState, useEffect} from "react";
import {
    Form, TextField, Select, FormThemeProvider,
} from "@react-md/form";

import {
    Dialog, DialogHeader, DialogTitle, DialogContent, DialogFooter,
} from '@react-md/dialog';
import {Button} from '@react-md/button';
import {Typography} from '@react-md/typography';
import {useToggle} from '@react-md/utils';

// Assets
import React from "react";
import theme from 'theme/theme.js';
import whiteBook from "../../assets/img/logo_cropped.png"

import {NavLink} from "react-router-dom";
import api from "../../api/api"
import styles from "./TextFieldTypes.module.scss";
import formApi from "./FormAPI"

import {stateData} from "./data/states";

import {useHistory} from "react-router-dom";
import {monthData} from "./data/months";
import {Tooltip} from "chart.js";


function CarForm({}) {

    const options = [{value: "Lease", label: "Lease"}, {value: "Finance", label: "Finance"}, {
        value: "Cash", label: "Cash"
    }]

    const [value, setValue] = useState('');
    const [valueError, setValueError] = useState(false)

    const [purchaseState, setPurchaseState] = useState('')
    const [purchaseStateError, setPurchaseStateError] = useState(false)

    const [purchaseMonth, setPurchaseMonth] = useState("")
    const [purchaseMonthError, setPurchaseMonthError] = useState(false)

    const [purchaseYear, setPurchaseYear] = useState("");
    const [yearError, setYearError] = useState(false);

    const [make, setMake] = useState('');
    const [makes, setMakes] = useState([]);

    const [makeError, setMakeError] = useState(false)

    const [model, setModel] = useState("");
    const [models, setModels] = useState([]);

    const [interest, setInterest] = useState(0);
    const [interestError, setInterestError] = useState(false)

    const [disableMakes, setDisableMakes] = useState(true);
    const [modelError, setModelError] = useState(false)

    const [trim, setTrim] = useState("");
    const [trimError, setTrimError] = useState(false)

    const [dealership, setDealership] = useState("")
    const [dealershipError, setDealershipError] = useState(false)

    const [down, setDown] = useState("");
    const [downError, setDownError] = useState(false);

    const [monthly, setMonthly] = useState("");
    const [monthlyError, setMonthlyError] = useState(false);

    const [residual, setResidual] = useState("");
    const [residualError, setResidualError] = useState(false);

    const [term, setTerm] = useState("");
    const [termError, setTermError] = useState(false);

    const [miles, setMiles] = useState("");
    const [milesError, setMilesError] = useState(false);

    const [specials, setSpecials] = useState("");
    const [specialsError, setSpecialsError] = useState(false)

    const [listed, setlisted] = useState("");
    const [listedError, setListedError] = useState(false)

    const [negotiated, setNegotiated] = useState("");
    const [negotiatedError, setNegotiatedError] = useState(false)

    const [visible, enable, disable] = useToggle(false);
    const [visibleFail, enableFail, disableFail] = useToggle(false);

    React.useEffect(() => {
        api.send("GET", "/brands/all", undefined, function (err, res) {
            // No Error expected
            setMakes(res.result)
        });
    }, [])


    useEffect(() => {
        api.send("GET", "/models/" + make, undefined, function (err, res) {
            // No Error expected
            if (res == null) {
                setDisableMakes(true)
            } else {
                setDisableMakes(false)
                setModels(res.result)
            }
        });
    }, [make])

    const history = useHistory();

    function navigateToDashboard() {
        history.push("/home")
    }

    const dealerships = ['Audi Stevens Creek', 'Audi San Francisco', 'Audi Palo Alto', 'BMW of Mountain View', 'Audi Concord'];
    const textColor = useColorModeValue("gray.700", "white");

    return (<ChakraProvider theme={theme} resetCss={false}>
        <Flex
            direction='column'
            justifySelf='center'
            overflow='hidden'
            marginTop="10px">

            <Flex alignItems='center' justifyContent='center' mb='70px' mt='70px'>
                <Flex
                    direction='column'
                    w={{lg: '70%', sm: '90%'}}
                    background='white'
                    borderRadius='15px'
                    p='40px'
                    boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>

                    <Grid templateColumns={{sm: "1fr ", lg: "0.13fr 1fr"}} gap={"30px"}>
                        <NavLink to="/home">
                            <Image
                                src={whiteBook}
                                alt='chakra image'
                                width="100%"
                                opacity={"60%"}
                            />
                        </NavLink>
                        <div>
                            <Text fontSize='30px' color={"gray.700"} fontWeight='bold'>
                                {"Welcome to the Movement!"}
                            </Text>
                            <Text fontSize='sm' color='gray.400' fontWeight='normal' marginBottom="60px">
                                {"Help others negotiate on their next car by bragging about your car deal"}
                            </Text>
                        </div>


                    </Grid>

                    <SimpleGrid gap="20px">
                        <SimpleGrid gap="20px" columns="2">
                            <TextField
                                id={`text-field-type-text`}
                                key={"text"}
                                type={"text"}
                                placeholder=""
                                label={"Dealership"}
                                onChange={(dealership) => {
                                    setDealership(dealership.target.value)
                                    console.log(dealership)
                                }}
                                error={dealershipError}
                            />

                            <Select
                                label="State"
                                name="state"
                                options={stateData}
                                value={purchaseState}
                                error={purchaseStateError}
                                onChange={(purchaseState) => setPurchaseState(purchaseState)}
                            />

                        </SimpleGrid>


                        <SimpleGrid columns={{sm: "1", lg: "3"}} gap="20px">
                            <Select
                                label="Make"
                                name="select"
                                options={makes}
                                value={make}
                                onChange={(make) => setMake(make)}
                                error={makeError}
                            />

                            <Select
                                label="Model"
                                name="select"
                                options={models}
                                value={model}
                                onChange={(model) => setModel(model)}
                                error={modelError}
                                disabled={make == ''}
                            />

                            <TextField
                                id={`text-field-type-text`}
                                key={"text"}
                                type={"text"}
                                label={"Trim"}
                                onChange={(trim) => setTrim(trim.target.value)}
                                error={trimError}

                            />
                        </SimpleGrid>


                        <Select
                            label="How did you buy your car?"
                            name="select"
                            options={options}
                            value={value}
                            onChange={(value) => setValue(value)}
                        />

                        {value == "Lease" ? <FormThemeProvider>
                            <Form className={styles.container} padding="0px"
                                  mx="0px" margin="0px">

                                <TextField
                                    id={`text-field-type-number`}
                                    key={"text"}
                                    type={"number"}
                                    placeholder=""
                                    label={"Down Payment"}
                                    onChange={(down) => setDown(down.target.value)}
                                    leftChildren={<BsCurrencyDollar></BsCurrencyDollar>}
                                    error={downError}
                                />

                                <TextField
                                    id={`text-field-type-number`}
                                    key={"number"}
                                    type={"number"}
                                    placeholder=""
                                    label={"Monthly Payment"}
                                    onChange={(monthly) => setMonthly(monthly.target.value)}
                                    leftChildren={<BsCurrencyDollar></BsCurrencyDollar>}
                                    error={monthlyError}
                                />

                                <TextField
                                    id={`text-field-type-number`}
                                    key={"text"}
                                    type={"number"}
                                    placeholder=""
                                    onChange={(residual) => setResidual(residual.target.value)}
                                    label={"Residual"}
                                    leftChildren={<BsCurrencyDollar></BsCurrencyDollar>}
                                    error={residualError}
                                />

                                <TextField
                                    id={`text-field-type-number`}
                                    key={"text"}
                                    type={"number"}
                                    placeholder=""
                                    label={"Term (months)"}
                                    onChange={(term) => setTerm(term.target.value)}
                                    error={termError}
                                />

                                <TextField
                                    id={`text-field-type-text`}
                                    key={"text"}
                                    type={"number"}
                                    placeholder=""
                                    label={"Miles / Year"}
                                    onChange={(miles) => setMiles(miles.target.value)}
                                    error={milesError}
                                />
                            </Form>

                        </FormThemeProvider> : null}
                        {value == "Finance" ? <FormThemeProvider>
                            <Form className={styles.container}>
                                <TextField
                                    id={`text-field-types-theme-number`}
                                    key={"text"}
                                    type={"number"}
                                    placeholder=""
                                    label={"Down Payment"}
                                    onChange={(down) => setDown(down.target.value)}
                                    leftChildren={<BsCurrencyDollar></BsCurrencyDollar>}
                                    error={downError}
                                />
                                <TextField
                                    id={`text-field-type-number`}
                                    key={"number"}
                                    type={"number"}
                                    placeholder=""
                                    label={"Monthly Payment"}
                                    onChange={(monthly) => setMonthly(monthly.target.value)}
                                    leftChildren={<BsCurrencyDollar></BsCurrencyDollar>}
                                    error={monthlyError}
                                />
                                <TextField
                                    id={`text-field-type-text`}
                                    key={"text"}
                                    type={"number"}
                                    placeholder=""
                                    label={"Term (months)"}
                                    onChange={(term) => setTerm(term.target.value)}
                                    error={termError}
                                />
                                <TextField
                                    id={`text-field-type-text`}
                                    key={"text"}
                                    type={"number"}
                                    placeholder=""
                                    label={"Interest Rate "}
                                    onChange={(int) => setInterest(int.target.value)}
                                    rightChildren={<BsPercent></BsPercent>}
                                    error={termError}
                                />
                            </Form>
                        </FormThemeProvider> : null}
                        {value == "Cash" ? <FormThemeProvider>
                            <Form className={styles.container}>
                                <TextField
                                    id={`text-field-type-text`}
                                    key={"text"}
                                    type={"number"}
                                    placeholder=""
                                    label={"Listed Price"}
                                    onChange={(listed) => setlisted(listed.target.value)}
                                    error={listedError}
                                    leftChildren={<BsCurrencyDollar></BsCurrencyDollar>}
                                />

                                <TextField
                                    id={`text-field-type-text`}
                                    key={"text"}
                                    type={"number"}
                                    label={"Price you paid"}
                                    onChange={
                                        (negotiated) => setNegotiated(negotiated.target.value)
                                    }
                                    error={negotiatedError}
                                    leftChildren={<BsCurrencyDollar></BsCurrencyDollar>}
                                />
                            </Form>
                        </FormThemeProvider> : null}

                        <SimpleGrid columns={{sm: "2", lg: "2"}}
                                    templateColumns={{sm: "2fr", lg: "2fr 0.5fr 0.5fr"}} gap="20px">

                            <TextField
                                id={`text-field-type-text`}
                                key={"text"}
                                type={"text"}
                                label={"Special Notes / Tips "}
                                onChange={(specials) => setSpecials(specials.target.value)}
                                rightChildren={
                                    <BsQuestionCircle/>
                                }
                                error={specialsError}
                            />

                            <Select
                                label="Month Signed"
                                name="state"
                                options={monthData}
                                value={purchaseMonth}
                                onChange={(purchaseMonth) => setPurchaseMonth(purchaseMonth)}
                            />

                            <TextField
                                id={`text-field-type-text`}
                                key={"text"}
                                type={"number"}
                                placeholder=""
                                label={"Year Signed"}
                                onChange={(year) => {
                                    setPurchaseYear(year.target.value)
                                }}
                                error={yearError}
                            />
                        </SimpleGrid>

                    </SimpleGrid>

                    <Icon
                        as={BsFillArrowRightCircleFill}
                        color="teal.300"
                        w='40px'
                        alignSelf={"flex-end"}
                        h='40px'
                        transition='all .5s ease'
                        cursor='pointer'
                        _hover={{transform: "scale(170%)"}}
                        onClick={(() => {

                            /**
                             * Base Request data
                             */
                            setMakeError(make == "")
                            setModelError(model == "")
                            setDealershipError(dealership == "")
                            setTrimError(trim == "")
                            setPurchaseStateError(purchaseState == "")

                            if (dealershipError || makeError || modelError || dealershipError || trimError || purchaseStateError) return
                            var commonData = formApi.addCommonData(make,
                                model,
                                trim,
                                dealership,
                                purchaseState,
                                specials,
                                purchaseMonth,
                                purchaseYear)
                            /**
                             *  LEASE REQUEST
                             */
                            if (value == "Lease") {
                                setDownError(down == "");
                                setMonthlyError(monthly == "");
                                setResidualError(residual == "");
                                setTermError(term == "");
                                setYearError(purchaseYear == "");
                                setMilesError(miles == "");
                                if (downError || monthlyError || residualError || termError || yearError || milesError) return
                                formApi.sendLease(down, monthly, residual, term, purchaseYear, miles, commonData, function (err, res) {
                                    if (err) {
                                        enableFail()
                                    } else {
                                        enable()
                                    }
                                },)

                                /**
                                 *  FINANCE REQUEST
                                 */
                            } else if (value == "Finance") {
                                setDownError(down == "")
                                setMonthlyError(monthly == "");
                                setTermError(term == "");
                                if (downError || monthlyError || termError) return
                                formApi.sendFinance(down, monthly, term, commonData, interest, function (err, res) {
                                    console.log("Sending finance")

                                    if (err) {
                                        enableFail()
                                    } else {
                                        enable()
                                    }
                                })

                                /**
                                 * CASH REQUEST
                                 */
                            } else if (value == "Cash") {
                                console.log(commonData)
                                setNegotiatedError(negotiated == "")
                                setListedError(listed == "")
                                if (negotiatedError || listedError) return
                                formApi.sendCash(listed, negotiated, commonData, function (err, res) {
                                    if (err) {
                                        enableFail()
                                    } else {
                                        enable()
                                    }
                                })
                            }
                        })}
                        pt='4px'
                    />

                    <Dialog
                        id="simple-dialog"
                        visible={visible}
                        onRequestClose={navigateToDashboard}
                        aria-labelledby="dialog-title"
                    >
                        <DialogHeader>
                            <DialogTitle id="dialog-title">Thank you! </DialogTitle>
                        </DialogHeader>
                        <DialogContent>
                            <Typography margin="none">Your input will be valuable in others getting amazing deals on
                                their next car. Please search for your car to find this entry.</Typography>
                        </DialogContent>
                        <DialogFooter>
                            <Button id="dialog-close" onClick={navigateToDashboard}>
                                Close
                            </Button>
                        </DialogFooter>
                    </Dialog>

                    <Dialog
                        id="simple-dialog"
                        visible={visibleFail}
                        onRequestClose={navigateToDashboard}
                        aria-labelledby="dialog-title"
                    >
                        <DialogHeader>
                            <DialogTitle id="dialog-title"> Something went wrong! </DialogTitle>
                        </DialogHeader>
                        <DialogContent>
                            <Typography margin="none">Your input is very important to us and others who are buying a
                                car. Please try again later.</Typography>
                        </DialogContent>
                        <DialogFooter>
                            <Button id="dialog-close" onClick={navigateToDashboard}>
                                Close
                            </Button>
                        </DialogFooter>
                    </Dialog>
                </Flex>
            </Flex>
        </Flex>
    </ChakraProvider>);
}

export default CarForm;
