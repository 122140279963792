import React from 'react';
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bubble} from 'react-chartjs-2';
import {useHistory} from "react-router-dom";

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

const BubbleChart = React.memo(({data, height}) => {
    const history = useHistory();

    const options = {
        plugins: {
            legend: {
                display: true
            },
            tooltip: {
                callbacks: {
                    title: (data) => {
                        if (data != null && data[0] != null) {
                            let rawData = data[0].raw
                            let purchaseType = data[0].dataset.label
                            if (purchaseType == "Lease") {
                                return "This vehicle was leased here"
                            } else if (purchaseType == "Finance") {
                                return "This vehicle was financed here"
                            } else {
                                return "invalid data point "
                            }
                        }
                        console.log(data)
                    },
                    label: (data, data2) => {
                        if (data != null) {
                            let rawData = data.raw
                            let purchaseType = data.dataset.label
                            if (purchaseType == "Lease" || purchaseType == "Finance") {
                                return "For $" + rawData.down_raw +
                                    " down and $" + rawData.y + "/month"
                            } else {
                                return "invalid data point "
                            }
                        }
                    },
                },
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: "Monthly Payments (in $)",
                    font: {
                        size: 14,
                    }
                },
                beginAtZero: false,
            },
            x: {
                title: {
                    display: true,
                    text: "Down Payment (in 1000s of $)",
                    font: {
                        size: 14,
                    }
                }
            }
        },
        maintainAspectRatio: false,
        onClick: (event, elements, chart) => {
            if (elements != null) {
                let element = elements.pop()
                if (element != null && data != null) {
                    let elementContext = element.element.$context
                    if (elementContext != null) {
                        console.log(elementContext)
                        let pk = elementContext.raw.primary_key
                        console.log(pk)
                        window.open("/deal/" + pk, "_blank");
                    }
                }
            }
        }
    };
    return <Bubble redraw={true}
                   options={options}
                   width={{sm: "60%", lg: "100%"}}
                   height={height}
                   data={data}/>
})

export default BubbleChart
