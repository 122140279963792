// Chakra imports
import {
    Box,
    Button,
    Flex,
    HStack,
    Image,
    Link,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import SidebarResponsive from "components/Sidebar/SidebarResponsive";
import PropTypes from "prop-types";
import React from "react";
import {NavLink} from "react-router-dom";
import routes from "routes.js";
import book from "assets/img/logo_cropped.png"

import {VerticalDivider} from "react-md";

import anime from "animejs";

export default function AuthNavbar(props) {
    const [open, setOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setOpen(!open);
    };
    const {logo, logoText, secondary, ...rest} = props;
    // verifies if routeName is the one activfe (in browser input)
    const activeRoute = (routeName) => {
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    };
    // Chakra color mode
    let navbarIcon = useColorModeValue("gray.700", "gray.200");
    const activeColor = useColorModeValue("gray.400", "white");
    let mainText = useColorModeValue("gray.700", "gray.200");
    let navbarBg = useColorModeValue(
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );
    let navbarBorder = useColorModeValue(
        "1.5px solid #FFFFFF",
        "1.5px solid rgba(255, 255, 255, 0.31)"
    );
    let navbarShadow = useColorModeValue(
        "0px 7px 23px rgba(0, 0, 0, 0.05)",
        "none"
    );
    let navbarFilter = useColorModeValue(
        "none",
        "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))"
    );
    let navbarBackdrop = "blur(21px)";
    let bgButton = useColorModeValue(
        "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
        "gray.800"
    );
    let navbarPosition = "fixed";
    let colorButton = "white";
    if (props.secondary === true) {
        navbarIcon = "white";
        navbarBg = "none";
        navbarBorder = "none";
        navbarShadow = "initial";
        navbarFilter = "initial";
        navbarBackdrop = "none";
        bgButton = "white";
        colorButton = "gray.700";
        mainText = "white";
        navbarPosition = "absolute";
    }
    var brand = (
        <Link
            href={`${process.env.PUBLIC_URL}/#/`}
            target="_blank"
            display="flex"
            lineHeight="100%"
            fontWeight="bold"
            justifyContent="center"
            alignItems="center"
            color={mainText}
            className="car-icon"
            paddingLeft="20px"
        >

            <Text fontSize="xl" fontWeight="bold" mt="3px"
                  color="teal.300" paddingLeft={"10px"}>
                {logoText}
            </Text>
        </Link>
    );
    var linksAuth = (
        <HStack display={{sm: "none", lg: "flex"}} flexDirection={{sm: "", lg: "row"}}>


            <NavLink to="/aboutUs">
                <Button
                    fontSize="lg"
                    px="0px"
                    me={{sm: "2px", md: "16px"}}
                    variant="transparent"
                >
                    <Text textColor={activeColor} me="10px">About Us</Text>
                </Button>
            </NavLink>

            <VerticalDivider/>

            <NavLink to="/comingsoon">
                <Button
                    fontSize="lg"
                    ms="16px"
                    px="0px"
                    me={{sm: "2px", md: "16px"}}
                    color={navbarIcon}
                    variant="transparent"
                >
                    <Text textColor={activeColor} me="10px">Newly Added</Text>
                </Button>
            </NavLink>

            <VerticalDivider/>

            <NavLink to="/fill">
                <Button
                    fontSize="lg"
                    ms="16px"
                    px="0px"
                    me={{sm: "2px", md: "16px"}}
                    variant="transparent"
                >
                    <Text textColor={activeColor} me="10px">Add your Payments</Text>
                </Button>
            </NavLink>
        </HStack>
    );

    window.onload = function () {
        anime({
            targets: 'div.caricon',
            translateX: {value: [-100, 20], duration: 2000},
        });
    }

    return (
        <Flex
            top="16px"
            left="50%"
            background={navbarBg}
            border={navbarBorder}
            boxShadow={navbarShadow}
            filter={navbarFilter}
            backdropFilter={navbarBackdrop}
            borderRadius={{lg: "15px", sm: "5px"}}
            px="16px"
            py="22px"
            mx="auto"
            width="100%"
            alignItems="center"
        >

            <div>
                <div>
                    <div>
                        <a>
                            <a href={`${process.env.PUBLIC_URL}/#/`}>
                                <img
                                    opacity="60%"
                                    src={book}
                                    width={"120px"}/>
                            </a>
                        </a>
                    </div>
                </div>
            </div>

            <Flex w="100%" justifyContent={{sm: "start", lg: "space-between"}}>
                {brand}
                <Box
                    ms={{base: "auto", lg: "0px"}}
                    display={{base: "flex", lg: "none"}}
                >
                    <SidebarResponsive
                        logoText={props.logoText}
                        secondary={props.secondary}
                        routes={routes}
                        // logo={logo}
                        {...rest}
                    />
                </Box>
                {linksAuth}
                <Link href="https://creative-tim.com/product/purity-ui-dashboard">
                </Link>
            </Flex>
        </Flex>
    );
}

AuthNavbar.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    brandText: PropTypes.string,
};
