import React from "react";
import {
    Tr,
    Td,
    Flex,
    Text,
    Progress,
    Icon,
    Button,
    useColorModeValue,
    Image
} from "@chakra-ui/react";
import {FaEllipsisV} from "react-icons/fa";
import CarFormPreviewDialog from "../../views/Form/CarFormPreviewDialog";
import {useToggle} from "@react-md/utils";

function CashesTableRow(props) {
    const {pk, dealer, trim, listed, negotiated} = props;
    const [visible, enable, disable] = useToggle(false);
    const textColor = useColorModeValue("gray.700", "white");
    return (
        <Tr onClick={() => {
            enable()
        }}>
            <Td>
                <Text fontSize="md" color={textColor}>
                    {dealer}
                </Text>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor}>
                    {trim}
                </Text>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor}>
                    $ {listed}
                </Text>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor}>
                    $ {negotiated}
                </Text>
            </Td>
            <CarFormPreviewDialog id={pk}
                                  shouldShow={visible}
                                  onClose={disable}></CarFormPreviewDialog>
        </Tr>

    );
}

export default CashesTableRow;
