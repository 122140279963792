// import
import Dashboard from "views/Dashboard/Dashboard";
import DataTables from "views/Dashboard/Tables";
import Billing from "views/Dashboard/Billing";
import RTLPage from "views/Dashboard/RTL";
import Profile from "views/Dashboard/Profile";
import SignIn from "./views/Form/SignIn.js";
import CarForm from "./views/Form/CarForm.js";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Newly Added ",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/comingsoon",
  },
  {
    path: "/tables",
    name: "Add your payments",
    icon: <StatsIcon color="inherit" />,
    component: DataTables,
    layout: "/fill",
  },
  {
    path: "/billing",
    name: "Did you sell your car?",
    icon: <CreditIcon color="inherit" />,
    component: Billing,
    layout: "/comingsoon",
  }]


//
//   {
//     name: "ACCOUNT PAGES",
//     category: "account",
//     rtlName: "صفحات",
//     state: "pageCollapse",
//     views: [
//       {
//         path: "/profile",
//         name: "Profile",
//         rtlName: "لوحة القيادة",
//         icon: <PersonIcon color="inherit" />,
//         secondaryNavbar: true,
//         component: Profile,
//         layout: "/admin",
//       }
//     ],
//   },
// ]
export default dashRoutes;
