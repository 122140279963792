// Chakra imports
import {
    Avatar,
    Box,
    Button,
    Center,
    Flex,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import {Grid} from "@chakra-ui/react";
import {useState, useEffect} from "react";
import {
    Select,
    FormThemeProvider
} from "@react-md/form";
import api from "../api/api"
import {useHistory, Link} from "react-router-dom";

const HeaderCarSelector = ({
                               title,
                               hasTopMargin
                           }) => {
    useColorModeValue(
        "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
        "gray.800"
    );
    const [model, setModel] = useState('');
    const [make, setMake] = useState('');
    const [models, setModels] = useState([]);


    const [makes, setMakes] = useState([]);
    const [disableMakes, setDisableMakes] = useState(true);

    useEffect(() => {
        api.send("GET", "/models/" + make, undefined, function (err, res) {
            // No Error expected
            if (res == null) {
                setDisableMakes(true)
            } else {
                setDisableMakes(false)
                setModels(res.result)
                setModel(res.result[0].value)
            }
        });
    }, [make])


    React.useEffect(() => {
        api.send("GET", "/brands/all", undefined, function (err, res) {
            // No Error expected
            setMakes(res.result)
        });
    }, [])

    const history = useHistory();

    function navigateToDashboard() {
        history.push("/dashboard", {"make": make, "model": model})
    }

    return (

        <Flex
            marginTop={hasTopMargin ? "20px" : "0px"}
            direction='column'
            background='white'
            borderRadius='15px'
            boxShadow='0 20px 27px 0 rgb(0 0 0 / 1%)'>

            <Box
                width="100%"
                display='flex'
                flexDirection='column'>

                <Box
                    borderRadius={{sm: "0px", lg: "10px"}}
                    position='relative'
                    alignContent="Center"
                >
                    <Text fontSize={{lg: "25px", sm: "25px"}} color='gray.500' fontWeight="bold"
                          marginTop={{sm: "5px", lg: "30px"}}
                          marginBottom="20px" marginLeft="50px">
                        {title}
                    </Text>
                    <Grid templateColumns={{md: "1fr 1fr 0.2fr", lg: "1fr 1fr 0.2fr", sm: "2fr 2fr 1fr"}}
                          templateRows={"1fr 1fr"}
                          gap={"30px"}
                          marginBottom={"-55px"}
                          marginX={{sm: "30px", lg: "50px"}}
                    >
                        <FormThemeProvider
                            theme={"underline"}>

                            <Select
                                label="Make"
                                name="select"
                                options={makes}
                                value={make}
                                onChange={(make) => setMake(make)}
                            />

                            <Select
                                label="Model"
                                name="select"
                                options={models}
                                value={model}
                                disabled={disableMakes}
                                onChange={(model) => setModel(model)}
                            />
                        </FormThemeProvider>


                        <Button borderRadius="5px"
                                height="100%"
                                backgroundColor={"#002242"}
                                color='white'
                                fontSize='xs'
                                variant='no-hover'
                                onClick={navigateToDashboard}>
                            SEARCH
                        </Button>
                    </Grid>
                </Box>
            </Box>
        </Flex>
    )
        ;
};

export default HeaderCarSelector;
