import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import LeasesTableRow from "components/Tables/LeasesTableRow";
import React from "react";

const Leases = ({ title, captions, data, numberPicker }) => {
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Card my={{sm:"3px", lg:'20px'}} overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p='6px 0px 10px 0px'>
        <Flex direction='column'>
          <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
            {title}
          </Text>
        </Flex>
      </CardHeader>
      <CardBody>
        <Table variant='simple' color={textColor}
        marginBottom={"-0px"}>
          <Thead>
            <Tr my='.1rem' pl='0px'>
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row) => {
              return (
                <LeasesTableRow
                  key={row.pk}
                  pk = {row.pk}
                  dealer={row.fields.dealership}
                  down={row.fields.lease.down}
                  monthly={row.fields.lease.monthly}
                  residual={row.fields.lease.residual}
                  miles={row.fields.lease.miles}
                  term = {row.fields.lease.term}
                  trim={row.fields.trim}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
      {numberPicker}
    </Card>
  );
};

export default Leases;
