// Chakra imports
import {ChakraProvider} from '@chakra-ui/react';
// Layout components2
import React from 'react';
import routes from 'routes.js';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// Custom Chakra theme
import theme from 'theme/theme.js';


// Custom components
import {Grid, Flex} from '@chakra-ui/react';
import AuthNavbar from 'components/Navbars/AuthNavbar';
import BubbleCard from 'views/Dashboard/Dashboard/components/BubbleCard';
import DataTables from 'views/Dashboard/Tables';
import StatisticsHighlight from '../views/Dashboard/Dashboard/components/StatisticsHighlight';
import StatisticsHighlightLease from '../views/Dashboard/Dashboard/components/StatisticsHighlightLease';

import HeaderCarSelectorMini from "../components/CarSelectorMini";
import CashesCardV2 from "../views/Dashboard/Tables/components/CashesCardRevamped";

import LeasesCardV2 from "../views/Dashboard/Tables/components/LeasesCardV2";
import FinancesCardV2 from "../views/Dashboard/Tables/components/FinancesCardV2";

export default function CarDashboard(props) {
    console.log("John stat e:" + props.location.state.make)
    console.log("John state:" + props.location.state.model)

    let make = props.location.state.make
    let model = props.location.state.model

    const [purchasedState, setPurchasedState] = React.useState("none");

    React.useEffect(() => {
        console.log(purchasedState, '- Has changed')
    }, [purchasedState])

    return (<ChakraProvider theme={theme} resetCss={false}>
        <Flex
            alignSelf={"center"}
            flexDirection={"row"}
            justifyContent={"center"}
            marginBottom={"0px"}
            marginTop={{sm: "0px", lg: "50px"}}
            marginX={{sm: "0px", lg: "50px", xl: "50px"}}
        >

            <Flex
                maxWidth={"1600px"}
                width={"100%"}
                flexDirection='column'
                paddingBottom={{sm: "0px", lg: "30px"}}>

                <AuthNavbar secondary={false} logoText=''/>

                <Grid
                    templateColumns={{sm: "1fr", md: "1fr", lg: "0.25fr 0.75fr"}}
                    templateRows="1fr"
                    marginTop={{sm: "3px", lg: "20px"}}
                    gap={{sm: "3px", lg: "20px"}}
                >
                    <HeaderCarSelectorMini
                        initMake={make}
                        initModel={model}
                        title={"Search for another car"}
                        onChange={(newPurchasedState) => setPurchasedState(newPurchasedState)}

                    />
                    <BubbleCard
                        height="400px"
                        make={props.location.state.make}
                        model={props.location.state.model}
                        state={purchasedState}
                    />
                </Grid>

                <Grid
                    templateColumns={{sm: "1fr", md: "1fr", lg: "0.25fr 0.25fr 0.75fr"}}
                    templateRows="1fr"
                    gap={{sm: "0px", lg: "20px"}}
                >
                    <StatisticsHighlight
                        make={props.location.state.make}
                        model={props.location.state.model}
                        state={purchasedState}
                    />

                    <StatisticsHighlightLease
                        make={props.location.state.make}
                        model={props.location.state.model}
                        state={purchasedState}
                    />

                    <CashesCardV2 make={make}
                                  model={model}/>
                </Grid>


                <Grid
                    templateColumns={{sm: "1fr", md: "1fr", lg: "0.5fr 0.5fr"}}
                    templateRows="1fr"
                    gap={{sm: "0px", lg: "20px"}}
                >
                    <FinancesCardV2 make={make} model={model}/>
                    <LeasesCardV2 make={make} model={model}/>
                </Grid>
            </Flex>
        </Flex>

    </ChakraProvider>);
}

