// Chakra imports
import {Button, Flex} from "@chakra-ui/react";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import React from "react";
import Bubble from 'components/Charts/Bubble';
import {ChakraProvider} from "@chakra-ui/react";

import theme from 'theme/theme.js';

import api from "../../../../api/api"
import NoEntries from "./NoEntries";
import {TextIconSpacing} from "react-md";
import {FiPlus} from "react-icons/fi";

import {NavLink, useHistory} from "react-router-dom";
import LoadingEntries from "./LoadingEntries";

const BubbleCard = ({make, model, state}) => {

    const [bubbleData, setBubbleData] = React.useState([]);
    const [showLoading, setShowLoading] = React.useState(false)


    React.useEffect(() => {
        let isMounted = true
        setShowLoading(true)
        api.send("GET", "/bubble/" + make + "/" + model + "/" + state, undefined, function (err, res) {
            setShowLoading(false)
            if (isMounted) setBubbleData(res.result)
        });
        return () => {
            isMounted = false
        };
    }, [state, make, model])

    const ref = React.useRef()

    return (<ChakraProvider theme={theme} resetCss={false}>
            <Flex direction='column'>
                <Card p='1.2rem' borderRadius={{sm: "0px", lg: "15px"}}>
                    <CardBody>
                        {
                            showLoading ? <LoadingEntries/> :
                                bubbleData.length == 0 ?
                                    <NoEntries
                                        title={"Oops! We don't have any data for that car!"}
                                        description={
                                            "If you own this car and would like to help others get " +
                                            "a good deal like you, tell everyone what you paid!"
                                        }
                                        button={
                                            <NavLink to="/fill">
                                                <Button id="combined-button-6" marginTop={"20px"}
                                                        themeType="outline">
                                                    <TextIconSpacing icon={<FiPlus width={"30px"}/>}>Add
                                                        Yours</TextIconSpacing>
                                                </Button>
                                            </NavLink>
                                        }
                                    /> :
                                    <Bubble data={{"datasets": bubbleData}} height={"500px"}/>
                        }

                    </CardBody>
                    <fwref ref={ref}></fwref>
                </Card>
            </Flex>
        </ChakraProvider>
    );
};

export default BubbleCard;
