class api {
    send(method, url, data, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            if (xhr.status !== 200) callback("[" + xhr.status + "]" + xhr.responseText, null);
            else {
                try {
                    callback(null, JSON.parse(xhr.responseText));
                } catch (e) {
                    callback("error", null)
                }
            }
        };
        xhr.open(method,  url, true);
        if (!data) xhr.send();
        else {
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.send(JSON.stringify(data));
        }
    }


    post(method, url, data, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            if (xhr.status !== 200) callback("[" + xhr.status + "]" + xhr.responseText, null);
            else {
                try {
                    callback(null, JSON.parse(xhr.responseText));
                } catch (e) {
                    callback(null, xhr.responseText)
                }
            }
        };
        xhr.open(method, url, true);
        if (!data) xhr.send();
        else {
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.send(JSON.stringify(data));
        }
    }
}

const a = new api();
export default a;