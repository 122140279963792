export const monthData = [
    {
        "value": "Jan",
        "label": "January"
    },
    {
        "value": "Feb",
        "label": "February"
    },
    {
        "value": "Mar",
        "label": "March"
    },
    {
        "value": "Apr",
        "label": "April"
    },
    {
        "value": "May",
        "label": "May"
    },
    {
        "value": "Jun",
        "label": "June"
    },
    {
        "value": "Jul",
        "label": "July"
    },
    {
        "value": "Aug",
        "label": "August"
    },
    {
        "value": "Sep",
        "label": "September"
    },
    {
        "value": "Oct",
        "label": "October"
    },
    {
        "value": "Nov",
        "label": "November"
    },
    {
        "value": "Dec",
        "label": "December"
    }
]