// Chakra imports
import {
    Button,
    Flex,
    Grid,
    Icon,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
// Assets
import imageArchitect1 from "assets/img/projects-1.jpg";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React from "react";
import {FaPlus} from "react-icons/fa";
import ProjectCardDashboard from "./ProjectCardDashboard";
import api from "../../../../api/api";

const Projects = ({title, description}) => {
    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");

    const [data, setData] = React.useState([])


    React.useEffect(() => {
        api.send("GET", "/highlights" + "/" + 1, undefined, function (err, res) {
            if (res != null) {
                console.log(res.list_data)
                if ("list_data" in res && !res.list_data.isEmpty) {
                    setData(res.list_data)
                }
            }
        });
    }, [])


    return (
        <Card pb="30px" p='16px' marginTop={{sm:"3px", lg:"25px"}} marginBottom={{lg: '30px', sm: "0px"}}
              borderRadius={{sm: "0px", lg: "15px"}}>
            <CardHeader p={{sm:"0px", lg:'12px 5px'}}>
                <Flex direction='column'>
                    <Text fontSize={{sm:"lg", lg:'2xl'}} color={textColor} fontWeight='bold'>
                        {title}
                    </Text>
                    <Text fontSize={{sm:"sm", md:'md'}} color='gray.500' fontWeight='400'>
                        {description}
                    </Text>
                </Flex>
            </CardHeader>
            <CardBody px='5px' mt={{sm:"5px", lg:"20px"}}>
                <Grid
                    width={"100%"}
                    templateColumns={{sm: "1fr 1fr", md: "repeat(3, 1fr)", lg: "repeat(3, 1fr)", xl: "repeat(6, 1fr)"}}
                    gap='5px'>
                    {data.map((row) => {
                        let isLeased = row.fields.lease != undefined
                        let monthly = isLeased ? row.fields.lease.monthly : row.fields.finance.monthly
                        return <ProjectCardDashboard
                            pk={row.pk}
                            make={row.make_label}
                            image={imageArchitect1}
                            category={row.model_label + " " + row.fields.trim}
                            name={row.fields.dealership}
                            description={
                                (row.fields.lease == undefined ? "Finance" : "Lease") +
                                "d for $" + monthly + "/month"}
                            avatars={[]}
                        />
                    })}

                </Grid>
            </CardBody>
        </Card>
    );
};

export default Projects;
