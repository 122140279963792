// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import FinancesTableRow from "components/Tables/FinancesTableRow";
import FinancesCardV2 from "./FinancesCardV2";
import React from "react";

const Finances = ({ title, captions, data, numberpicker }) => {
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Card mt={{sm:"3px", lg:'10px'}} overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p='6px 0px 10px 0px'>
        <Flex direction='column'>
          <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
            {title}
          </Text>
        </Flex>
      </CardHeader>
      <CardBody
      marginBottom={"20px"}>
        <Table variant='simple' color={textColor}>
          <Thead>
            <Tr my='.1rem' pl='0px'>
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row) => {
              return (
                <FinancesTableRow
                  my="5px"
                  key={row.pk}
                  pk={row.pk}
                  dealer={row.fields.dealership}
                  down={row.fields.finance.down}
                  monthly={row.fields.finance.monthly}
                  term={row.fields.finance.term}
                  trim={row.fields.trim}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
      {numberpicker}
    </Card>
  );
};

export default Finances;
