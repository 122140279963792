// Chakra imports
import {
    Avatar, Box, Button, Center, Flex, Image, Text, useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import {Grid} from "@chakra-ui/react";
import {useState, useEffect} from "react";
import {
    Select,
    FormThemeProvider
} from "@react-md/form";
import api from "../api/api"
import {useHistory, Link} from "react-router-dom";
import {stateData} from "../views/Form/data/states";

const HeaderCarSelectorMini = ({
                                   initMake, initModel, title, onChange
                               }) => {
    useColorModeValue("linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)", "gray.800");
    const [model, setModel] = useState(initModel);
    const [make, setMake] = useState(initMake);
    const [models, setModels] = useState([]);


    const [makes, setMakes] = useState([]);
    const [disableMakes, setDisableMakes] = useState(true);

    const [localPurchaseState, setLocalPurchaseState] = React.useState("")

    useEffect(() => {
        api.send("GET", "/models/" + make, undefined, function (err, res) {
            // No Error expected
            if (res == null) {
                setDisableMakes(true)
            } else {
                setDisableMakes(false)
                setModels(res.result)
            }
        });
    }, [make])

    React.useEffect(() => {
        api.send("GET", "/brands/all", undefined, function (err, res) {
            // No Error expected
            setMakes(res.result)
        });
    }, [])

    const history = useHistory();

    function navigateToDashboard() {
        history.push("/dashboard", {"make": make, "model": model})
    }

    return (
        <FormThemeProvider
            theme={"underline"}>
            <Flex
                direction='column'
                background='white'
                justifyContent={"center"}
                borderRadius='15px'
                height="100%"
                boxShadow='0 5px 5px 0 rgb(0 0 0 / 2%)'>
                <Box
                    px='0px'
                    width="100%"
                    display='flex'
                    marginBottom={{sm: "0px", lg: "30px"}}
                    flexDirection='column'>

                    <Box
                        borderRadius={{sm: "0px", lg: "10px"}}
                        position='relative'
                        alignContent="Center"
                    >
                        <Grid templateColumns={{md: "1fr ", lg: "1fr", sm: "1fr 1fr 1fr"}}
                              marginTop={"20px"}
                              gap={"10px"}
                              rowGap={{sm: "5px", lg: "10px"}}
                              marginX={"25px"}
                              paddingBottom={{sm: "10px", lg: "40px"}}>

                            <Select
                                label="Make"
                                name="select"
                                options={makes}
                                value={make}
                                onChange={(make) => setMake(make)}
                            />

                            <Select
                                label="Model"
                                name="select"
                                options={models}
                                value={model}
                                disabled={disableMakes}
                                onChange={(model) => setModel(model)}
                            />

                            {initMake == make && initModel == model ?
                                <Button borderRadius="5px"
                                        height="100%"
                                        backgroundColor={"#002242"}
                                        color='white'
                                        fontSize='md'
                                        marginBottom={{sm: "0px", lg: "30px"}}
                                        onClick={navigateToDashboard}
                                        _hover={"none"}
                                        disabled>
                                    SEARCH
                                </Button>
                                : <Button borderRadius="5px"
                                          height="100%"
                                          backgroundColor={"#002242"}
                                          color='white'
                                          fontSize='md'
                                          marginBottom={{sm: "0px", lg: "30px"}}
                                          onClick={navigateToDashboard}>
                                    SEARCH FOR ANOTHER CAR
                                </Button>
                            }
                        </Grid>

                        <Grid templateColumns={{md: "1fr ", lg: "1fr", sm: "1fr"}}
                              marginX={"25px"}
                              paddingBottom={{sm: "10px", lg: "0px"}}>

                            <Select
                                label="Filter by state"
                                name="state"
                                options={stateData}
                                value={localPurchaseState}
                                onChange={(purchaseState) => {
                                    setLocalPurchaseState(purchaseState)
                                    onChange(purchaseState)
                                    console.log("")
                                }}
                            />

                        </Grid>

                    </Box>
                </Box>
            </Flex>
        </FormThemeProvider>);
};

export default HeaderCarSelectorMini;
