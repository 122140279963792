// Chakra imports
import {Flex} from "@chakra-ui/react";
import React from "react";
import Authors from "./components/Authors";
import Finances from "./components/Finances";
import Leases from "./components/Leases"
import {tablesTableData, dashboardTableData} from "variables/general";
import api from "../../../api/api"
import {StyledEngineProvider} from '@mui/material/styles';

import Pagination from "@mui/material/Pagination";
import {createTheme} from "@mui/material";
import {ThemeProvider} from "@mui/material";

function DataTables({make, model, state}) {

    const [finance, setFinance] = React.useState([]);
    const [lease, setLease] = React.useState([]);

    const [financePages, setFinancePages] = React.useState(1)
    const [financePage, setFinancePage] = React.useState(1)

    const [leasePages, setLeasePages] = React.useState(1)
    const [leasePage, setLeasePage] = React.useState(1)

    const theme = createTheme({
        palette: {
            secondary: {
                main: '#E33E7F',
            }
        }
    });

    React.useEffect(() => {
        api.send("GET", "/finance/all/" + make + "/" + model + "/" + state + "/" + (financePage - 1), undefined, function (err, res) {
            // No Error expected
            if (res != null) {
                setFinancePages(res.page_count)
                console.log("John")
                console.log(res)
                if ("list_data" in res && !res.list_data.isEmpty) {
                    setFinance(res.list_data)
                }
            }
        });
    }, [state, financePage, make, model ])

    React.useEffect(() => {
        api.send("GET", "/lease/all/" + make + "/" + model + "/" + state + "/" + (leasePage - 1), undefined, function (err, res) {
            // No Error expected
            if (res != null) {
                setLeasePages(res.page_count)
                if ("list_data" in res && res.list_data != "[]") {
                    console.log("John Lease test data!!!!")
                    console.log(res.list_data)
                    setLease(res.list_data)
                }
            }
        });
    }, [state, leasePage, make, model ])

    return (<Flex direction='column' pt={{sm:"0px", base: "10px", md: "10px"}}>
        {
            finance.length != 0 ?
                <Finances
                    title={"Closed Finance Deals "}
                    captions={["Dealership", "trim", "Down", "Monthly", "term"]}
                    data={finance}
                    numberpicker={
                        financePages > 1 ?
                            <Flex marginBottom={"10pt"} alignContent={"center"} justifyContent={"center"}>
                                <ThemeProvider theme={theme}>
                                    <Pagination count={financePages}
                                                onChange={(event, page) => {
                                                    setFinancePage(page)
                                                    console.log(page)
                                                }}
                                                color="secondary"/>
                                </ThemeProvider>
                            </Flex> : <p></p>

                    }
                /> :
                <p></p>
        }
        {
            lease.length != 0 ?
                <Leases
                    title={"Closed Lease Deals "}
                    captions={["Dealership", "Trim", "Down", "Monthly", "term", "Miles / year ", "Residual"]}
                    data={lease}
                    numberPicker={leasePages > 1 ?
                        <Flex alignContent={"center"} justifyContent={"center"}>
                            <ThemeProvider theme={theme}>
                                <Pagination count={leasePages}
                                            onChange={(event, page) => {
                                                setLeasePage(page)
                                                console.log(page)
                                            }}
                                            color="secondary"/>
                            </ThemeProvider>
                        </Flex> :
                        <p></p>
                    }
                /> :
                <p></p>
        }
    </Flex>);
}

export default DataTables;
