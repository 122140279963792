import {Flex, Progress, Text, useColorModeValue} from "@chakra-ui/react";
import IconBox from "components/Icons/IconBox";
import React from "react";

const ChartStatistics = ({title, amount, percentage, goodPrice, suggestions}) => {
    const iconTeal = useColorModeValue("teal.300", "teal.300");
    const color = suggestions ? ("white") : ("gray.400")
    const size = suggestions ? ('sm') : ('2xl')
    const display = suggestions ? ("none") : "flex"
    return (
        <Flex direction='column' marginTop="-20px">
            <Flex>
                <Text textAlign={"center"} fontSize={{sm: "sm", lg: "lg"}} color={color} fontWeight='semibold'>
                    {title}
                </Text>
            </Flex>
            <Text  textAlign={"center"} fontSize={size}
                  color={goodPrice ? ("#66cf51") : ("#ff8787")} fontWeight='bold'>
                {amount}
            </Text>
            <Progress
                colorScheme='teal'
                borderRadius='12px'
                h='2px'
                mb={"10px"}
                mx={"30px"}
                value={percentage}
            />
        </Flex>
    );
};

export default ChartStatistics;
