// Chakra imports
import {Flex, SimpleGrid, Text, useColorModeValue, Image} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// Custom logos
import React from "react";
import ChartStatistics from "./ChartStatistics";
import api from "./../../../../api/api.js"
import {Select} from "@react-md/form";
import {stateData} from "../../../Form/data/states";
import CarSelectorMini from "../../../../components/CarSelectorMini";


const StatisticsHighlight = ({make, model, state}) => {

    const [minMaxData, setMinMaxData] = React.useState({});

    React.useEffect(() => {
        console.log(make)
        console.log(model)
        if (make != null && model != null) {
            api.send("GET", "/minmax/" + make + "/" + model + "/" + state, undefined, function (err, res) {
                console.log(res)
                setMinMaxData(res)
            });
        }
    }, [state, make, model])

    return (
        <div>
            {
                !minMaxData.has_no_values ?
                    <Card marginTop={{sm: "3px", lg: "20px"}} h={"100% - 20px"}>
                        <CardBody>
                            <Flex direction='column' w='100%'>
                                <Text fontSize='xl' fontWeight='bold' marginBottom={"20px"}>
                                    <Flex direction="row">
                                        {"Finance Deal Statistics"}
                                    </Flex>
                                </Text>
                                <SimpleGrid gap={{sm: "12px", lg: "20px"}}
                                            columns={{sm: 4, md: 4, lg: 2, xl: 2}}
                                            marginTop="20px"
                                            marginBottom={{sm: "0px", lg: "100px"}}>
                                    <ChartStatistics
                                        title={"Avg. Down"}
                                        amount={"$ " + minMaxData.avg_down_finance / 1000 + "K"}
                                        percentage={100}
                                        goodPrice={false}
                                        suggestions={false}

                                    />
                                    <ChartStatistics
                                        title={"Min. Down"}
                                        amount={"$ " + minMaxData.min_down_finance / 1000 + "K"}
                                        percentage={100}
                                        goodPrice={true}
                                        suggestions={false}
                                    />

                                    <ChartStatistics
                                        title={"Avg. Monthly"}
                                        amount={"$ " + minMaxData.avg_monthly_finance / 1000 + "K"}
                                        percentage={100}
                                        goodPrice={false}
                                        suggestions={false}

                                    />
                                    <ChartStatistics
                                        title={"Min. Monthly"}
                                        amount={"$ " + minMaxData.min_monthly_finance / 1000 + "K"}
                                        percentage={100}
                                        goodPrice={true}
                                        suggestions={false}
                                    />
                                </SimpleGrid>
                            </Flex>
                        </CardBody>
                    </Card> : <div></div>
            }
        </div>);
};

export default StatisticsHighlight;
