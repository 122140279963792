import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import HomePage from "layouts/HomePage.js"
import CarDashboard from "layouts/CarDashboard";
import CarForm from "./views/Form/CarForm";
import ComingSoon from "./layouts/ComingSoon";
import CarFormPreview from "./views/Form/CarFormPreview";
import AboutUs from "./layouts/AboutUs";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path={`/auth`} component={AuthLayout} />
      <Route path={`/admin`} component={AdminLayout} />
      <Route path={`/home`} component={HomePage} />
      <Route path={`/fill`} component={CarForm} />
      <Route path={`/comingsoon`} component={ComingSoon} />
      <Route path={`/aboutus`} component={AboutUs} />
      <Route path={`/dashboard`} component={CarDashboard} />
      <Route path={`/deal/:id`} component={CarFormPreview} />
      <Redirect from='/' to='/home' />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
