import api from "../../api/api"

class formApi {

    addCommonData(
        make,
        model,
        trim,
        dealership,
        purchaseState,
        specials,
        monthSigned,
        yearSigned
    ) {
        var data = {}
        data.make = make
        data.model = model
        data.trim = trim
        data.dealership = dealership
        data.purchaseState = purchaseState
        data.special_programs = specials
        data.month_signed = monthSigned
        data.year_signed = yearSigned
        return data
    }

    sendLease(down,
              monthly,
              residual,
              term,
              year,
              miles,
              data,
              callback,
    ) {
        var newData = {}
        Object.assign(newData, data);
        newData.lease = {}
        newData.lease.down = down
        newData.lease.monthly = monthly
        newData.lease.residual = residual
        newData.lease.term = term
        newData.lease.year = year
        newData.lease.miles = miles
        api.post(
            "POST",
            "/submit",
            newData,
            callback
        )
    }


    sendFinance(down,
                monthly,
                term,
                data,
                interest,
                callback
    ) {
        var newData = {}
        Object.assign(newData, data);

        newData.finance = {}
        newData.finance.down = down
        newData.finance.monthly = monthly
        newData.finance.term = term
        newData.finance.interest = interest
        api.post(
            "POST",
            "/submit",
            newData,
            callback
        )
    }

    sendCash(listed,
             negotiated,
             data,
             callback,
    ) {

        var newData = {}
        Object.assign(newData, data);

        newData.cash = {}
        newData.cash.listed = listed
        newData.cash.negotiated = negotiated
        api.post(
            "POST",
            "/submit",
            newData,
            callback
        )
    }

}

const a = new formApi();
export default a;