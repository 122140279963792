import React from "react";
import {
    Tr,
    Td,
    Flex,
    Text,
    Progress,
    Icon,
    Button,
    useColorModeValue,
    Image
} from "@chakra-ui/react";
import {FaEllipsisV} from "react-icons/fa";
import CarFormPreviewDialog from "../../views/Form/CarFormPreviewDialog";
import {useToggle} from "@react-md/utils";

function LeasesTableRow(props) {
    const {pk, dealer, down, monthly, residual, miles, trim, term, specials} = props;
    const textColor = useColorModeValue("gray.700", "white");
    const [visible, enable, disable] = useToggle(false);

    return (
        <Tr onClick={() => {
            enable()
        }}>
            <Td>
                <Text fontSize="md" color={textColor}>
                    {dealer}
                </Text>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor}>
                    {trim}
                </Text>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor}>
                    $ {down}
                </Text>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor}>
                    $ {monthly}
                </Text>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor}>
                    {term} months
                </Text>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor}>
                    {miles}
                </Text>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor}>
                    $ {residual}
                </Text>
            </Td>
            <CarFormPreviewDialog id={pk}
                                  shouldShow={visible}
                                  onClose={disable}></CarFormPreviewDialog>
        </Tr>
    );
}

export default LeasesTableRow;
