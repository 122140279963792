// Chakra imports
import {
    Box,
    Button,
    Flex,
    Icon,
    Portal,
    Spacer,
    Text,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React from "react";
// react logos

const IntroCard = ({title, description, backgroundImage}) => {
    const overlayRef = React.useRef();
    return (
        <Card maxHeight='400pxpx' p='1rem' borderRadius={{sm: "0px", lg: "15px"}}>
            <CardBody
                p='0px'
                backgroundImage={backgroundImage}
                bgPosition='center'
                bgRepeat='no-repeat'
                w='100%'
                h={{sm: "200px", lg: "100%"}}
                bgSize='cover'
                position='relative'
                borderRadius='10px'>
                <Box
                    bg='linear-gradient(360deg, rgba(77, 182, 172, 1) 10%, rgba(77, 182, 172, 0) 90%)'
                    w='100%'
                    position='absolute'
                    h='inherit'
                    borderRadius='inherit'
                    ref={overlayRef}></Box>
                <Portal containerRef={overlayRef}>
                    <Flex
                        flexDirection='column'
                        color='white'
                        p='1.5rem 1.2rem 0.3rem 1.2rem'
                        lineHeight='1.6'>

                        <Flex direction="column">
                            {/*_hover={{transform: "scale(105%)"}}*/}
                            <Text position="absolute" bottom="0" mt='12px'
                                  fontSize={{sm: 'lg', lg: '2xl'}} marginBottom={"20px"} fontWeight='bold'
                                  flexWrap="wrap">
                                {title}
                            </Text>
                        </Flex>
                    </Flex>
                </Portal>
            </CardBody>
        </Card>
    );
};

export default IntroCard;
