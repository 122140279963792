// Chakra imports
import {
    Flex, Icon, SimpleGrid, Text, ChakraProvider
} from "@chakra-ui/react";

import {BsCurrencyDollar, BsPercent} from "react-icons/bs";
import {useState, useEffect} from "react";
import {
    Form, TextField, Select, FormThemeProvider,
} from "@react-md/form";

// Assets
import React from "react";
import theme from 'theme/theme.js';

import styles from "./TextFieldTypes.module.scss";


import {mirage} from "ldrs";

import {useHistory} from "react-router-dom";
import api from "../../api/api";
import {useParams} from 'react-router-dom';

function CarFormPreview({pk}) {
    mirage.register()
    let id = useParams().id
    console.log(pk)
    if (id == undefined) {
        id = pk
    }
    console.log(id)

    const history = useHistory();
    const [purchaseType, setPurchaseType] = useState("")
    const [entry, setEntry] = useState(undefined)

    const financePage = 2
    React.useEffect(() => {
        api.send("GET", "/item/" + (id), undefined, function (err, res) {
            // No Error expected
            if (res != null) {
                setEntry(res[0])
                console.log(res[0])

                if (res[0].fields.lease != null) {
                    setPurchaseType("Lease")
                } else if (res[0].fields.finance != null) {
                    setPurchaseType("Finance")
                } else {
                    setPurchaseType("Cash")
                }
            }
        });
    }, [])

    function navigateToDashboard() {
        history.push("/home")
    }


    return (<ChakraProvider theme={theme} resetCss={false}>
        <Flex
            direction='column'
            justifySelf='center'
            overflow='hidden'
            marginTop="10px">
            <Flex alignItems='center' justifyContent='center' mb={pk == undefined ? '70px' : ""}
                  mt={pk == undefined ? '70px' : ""}>
                <Flex
                    direction='column'
                    w={pk == undefined ? {lg: '70%', sm: '90%'} : {lg: '', sm: ''}}
                    background='white'
                    borderRadius='15px'
                    p='40px'
                    boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>

                    <Text fontSize='30px' color={"gray.700"} fontWeight='bold'>
                        {"Full Details"}
                    </Text>
                    <Text fontSize='sm' color='gray.400' fontWeight='normal' marginBottom="40px">
                        {"You can use this to negotiate your next car purchase"}
                    </Text>

                    {entry != undefined ? <SimpleGrid gap="20px">
                            <SimpleGrid gap="20px" columns="2">
                                <TextField
                                    readOnly
                                    value={entry.fields.dealership}
                                    id={`text-field-type-text`}
                                    key={"text"}
                                    type={"text"}
                                    placeholder=""
                                    label={"Dealership"}
                                />

                                <TextField
                                    value={entry.fields.purchased_state}
                                    readOnly
                                    label="State"
                                    name="state"
                                />
                            </SimpleGrid>


                            <SimpleGrid columns={{sm: "1", lg: "3"}} gap="20px">
                                <TextField
                                    readOnly
                                    label="Make"
                                    name="select"
                                    value={entry.make_label}
                                />

                                <TextField
                                    readOnly
                                    label="Model"
                                    name="select"
                                    value={entry.model_label}

                                />

                                <TextField
                                    readOnly
                                    id={`text-field-type-text`}
                                    key={"text"}
                                    type={"text"}
                                    label={"Trim"}
                                    value={entry.fields.trim}
                                />
                            </SimpleGrid>

                            <TextField
                                readOnly
                                label="How was the car bought?"
                                name="select"
                                value={purchaseType}
                            />

                            {purchaseType == "Lease" ? <FormThemeProvider>
                                <Form className={styles.container} padding="0px"
                                      mx="0px" margin="0px">

                                    <TextField
                                        readOnly
                                        id={`text-field-type-number`}
                                        key={"text"}
                                        label={"Down Payment"}
                                        value={entry.fields.lease.down + " "}
                                        leftChildren={<BsCurrencyDollar></BsCurrencyDollar>}
                                    />

                                    <TextField
                                        readOnly
                                        id={`text-field-type-number`}
                                        key={"number"}
                                        label={"Monthly Payment"}
                                        value={entry.fields.lease.monthly + " "}
                                        leftChildren={<BsCurrencyDollar></BsCurrencyDollar>}
                                    />

                                    <TextField
                                        readOnly
                                        id={`text-field-type-number`}
                                        label={"Residual"}
                                        value={entry.fields.lease.residual + " "}
                                        leftChildren={<BsCurrencyDollar></BsCurrencyDollar>}
                                    />

                                    <TextField
                                        readOnly
                                        id={`text-field-type-number`}
                                        value={entry.fields.lease.term + " "}
                                        label={"Term (months)"}
                                    />

                                    <TextField
                                        readOnly
                                        id={`text-field-type-text`}
                                        type={"month"}
                                        value={entry.fields.lease.residual + " "}
                                        label={"Year Signed"}
                                    />

                                    <TextField
                                        readOnly
                                        id={`text-field-type-text`}
                                        value={entry.fields.lease.miles + " "}
                                        label={"Miles / Year"}
                                    />
                                </Form>

                            </FormThemeProvider> : null}

                            {purchaseType == "Finance" ? <FormThemeProvider>
                                <Form className={styles.container}>
                                    <TextField
                                        readOnly
                                        id={`text-field-types-theme-number`}
                                        key={"text"}
                                        label={"Down Payment"}
                                        value={entry.fields.finance.down + " "}
                                        leftChildren={<BsCurrencyDollar></BsCurrencyDollar>}
                                    />

                                    <TextField
                                        readOnly
                                        id={`text-field-type-number`}
                                        label={"Monthly Payment"}
                                        value={entry.fields.finance.monthly + " "}
                                        leftChildren={<BsCurrencyDollar></BsCurrencyDollar>}
                                    />

                                    <TextField
                                        readOnly
                                        value={entry.fields.finance.term + " "}
                                        label={"Term (months)"}
                                    />
                                    <TextField
                                        readOnly
                                        value={entry.fields.finance.interest + " "}
                                        rightChildren={<BsPercent></BsPercent>}
                                        label={"Interest"}
                                    />
                                </Form>
                            </FormThemeProvider> : null}

                            {purchaseType == "Cash" ? <FormThemeProvider>
                                <Form className={styles.container}>
                                    <TextField
                                        readOnly
                                        id={`text-field-type-number`}
                                        label={"Listed Price"}
                                        placeholder=""
                                        value={entry.fields.cash.listedPrice + " "}
                                    />

                                    <TextField
                                        readOnly
                                        id={`text-field-type-number`}
                                        label={"Negotiated Price"}
                                        placeholder=""
                                        value={entry.fields.cash.negotiatedPrice + " "}
                                    />
                                </Form>
                            </FormThemeProvider> : null}

                            <SimpleGrid columns={"1"} gap="20px">
                                <TextField
                                    readOnly
                                    id={`text-field-type-text`}
                                    key={"text"}
                                    value={entry.fields.special_programs == undefined ? "" : entry.fields.special_programs}
                                    type={"text"}
                                    placeholder="Please enter if you have any special notes or tips to get this price"
                                    label={"Special Notes / Tips "}
                                />

                            </SimpleGrid>

                            <SimpleGrid columns={"2"} gap="20px">
                                <TextField
                                    readOnly
                                    id={`text-field-type-text`}
                                    key={"text"}
                                    value={entry.fields.purchased_month == undefined ? "" : entry.fields.purchased_month}
                                    type={"text"}
                                    placeholder="Please enter if you have any special notes or tips to get this price"
                                    label={" Year Purchased "}
                                />
                                <TextField
                                    readOnly
                                    id={`text-field-type-text`}
                                    key={"text"}
                                    value={(entry.fields.purchased_year == undefined ? "" : entry.fields.purchased_year ) + " "}
                                    type={"text"}
                                    label={"Month purchased "}
                                />
                            </SimpleGrid>
                        </SimpleGrid> :
                        <l-mirage
                            size="80"
                            speed="5"
                            color="teal"
                        />}
                </Flex>
            </Flex>
        </Flex>
    </ChakraProvider>);
}

export default CarFormPreview;
