import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle} from "@react-md/dialog";
import {Typography} from "@react-md/typography";
import {Button} from "@chakra-ui/react";
import CarFormPreview from "./CarFormPreview";
import {Box} from "@mui/material"
const CarFormPreviewDialog = ({id, shouldShow, onClose}) => {
    return (
        <Dialog
            id="simple-dialog"
            visible={shouldShow}
            onRequestClose={onClose}
            aria-labelledby="dialog-title"
        >
            <Box className="modalBox" sx={{overflowY: "scroll"}}>
                <CarFormPreview pk={id}></CarFormPreview>

            </Box>
        </Dialog>
    );
}
export default CarFormPreviewDialog